import type {
  PersonalSchedule,
  TeamSchedule,
  FloorDeskSchedule,
} from '../types/schedule'
import { getDefaultHeaderConfig } from './utils'
import { startOfDay, endOfDay, format } from 'date-fns'

export const getUserSchedule = (
  userId: number | 'me',
  startDate: Date = startOfDay(new Date()),
  endDate: Date = endOfDay(new Date())
  ) => {
    return $fetch<PersonalSchedule>(
      `/api/v1/users/${userId}/work_activity_schedule.json`,
    {
      headers: getDefaultHeaderConfig(),
      params: {
        start_date: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
        end_date:format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
      },
    }
  )
}

export const getTeamSchedule = (
  teamId: number | 'me',
  startDate: Date = startOfDay(new Date()),
  endDate: Date = endOfDay(new Date()),
  {
    favoriteColleagues = false,
    managementTeam = false,
    managedTeam = false,
  }: {
    favoriteColleagues?: boolean
    managementTeam?: boolean
    managedTeam?: boolean
  }
) => {
  return $fetch<TeamSchedule>(
    `/api/v1/teams/${teamId}/work_activity_schedule.json`,
    {
      headers: getDefaultHeaderConfig(),
      params: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        favorites_team: favoriteColleagues,
        management_team: managementTeam,
        managed_team: managedTeam,
      },
    }
  )
}

export const getFloorDesksSchedule = (
  floorId: number,
  startDate: Date,
  endDate: Date
) => {
  return $fetch<FloorDeskSchedule>(
    `/api/v1/floors/${floorId}/desks_schedule.json`,
    {
      headers: getDefaultHeaderConfig(),
      query: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
    }
  )
}
